@use '@angular/material' as mat;
@use "sass:math";
@import 'colors';

.tv-dialog {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  $tv-dialog-padding: 24px;
  position: relative;
  $header-color: mat.get-color-from-palette($primary, default-contrast);

  @media (max-width: 800px) {
    width: 100%;
  }

  > * {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  .tv-dialog__header {
    margin: (-$tv-dialog-padding) (-$tv-dialog-padding) 0;
    padding: math.div($tv-dialog-padding, 2) $tv-dialog-padding;
    background-color: mat.get-color-from-palette($primary);
    color: $header-color;

    > .tv-dialog__header__title {
      font-weight: bold;
    }

    > .tv-dialog__header__subtitle {
      font-size: 0.9em;
    }
  }

  .tv-dialog__header-actions {
    position: absolute;
    top: -24px;
    right: -24px;
    padding: 10px;

    [mat-icon-button] {
      $button-size: 30px;
      width: $button-size;
      height: $button-size;

      mat-icon {
        $icon-size: 15px;
        font-size: $icon-size;
        width: $icon-size;
        height: $icon-size;
        color: $header-color;
      }
    }

  }

  .tv-dialog__content {
    padding-top: ($tv-dialog-padding);
    flex: 1;
    overflow: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .tv-dialog__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.mat-overlay-pane {
  display: flex;
  max-height: 100vh;

  mat-dialog-container {
    focus-trap {
      display: flex;
      max-height: 100%;

      > div:nth-child(2) {
        display: flex;
      }
    }
  }

  @media (max-width: 800px) {
    width: 100vw;
    top: 0 !important;
    left: 0 !important;
    transform: translate(0, 0) !important;

    mat-dialog-container {
      &, & focus-trap {
        &, & > div:nth-child(2) {
          width: 100%;
        }
      }
    }
  }
}
