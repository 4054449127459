@use '@angular/material' as mat;
@import 'theme';
@import 'loading';
@import 'dialog';

html, body {
  background-color: map-get(map-get($theme, background), background);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
}

a {
  &, &:hover, &:visited {
    color: $primaryColor;
  }
}

*::selection {
  background: transparentize($primaryColor, 0.8);
}

.tv-clickable {
  &, &:hover {
    cursor: pointer;
  }
}

hr {
  border: none;
  border-top: 1px solid mat.get-color-from-palette($primary);
}

mat-card.mat-card--image-only {
  margin: 0;
  padding: 0;

  > .mat-card-image:first-child {
    margin: 0;
    width: 100%;
    display: block;
  }
}

a.tv-imprint {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 2px 3px;
  text-decoration: none;
  font-size: 0.8rem;
  color: mat.get-color-from-palette(map-get($theme, foreground), text);
  box-shadow: 0 0 1px 0 currentColor;
  border-top-left-radius: 0.4rem;
  background: mat.get-color-from-palette(map-get($theme, background), card);
}

tv-act-details {
  h2, h3, h4 {
    text-align: center;
  }
}

.tv-main-content > router-outlet + * {
  flex: 1;
  overflow-y: auto;
  padding: 10px;

  @media (min-width: 801px) {
    padding-top: 40px;
  }
}

snack-bar-container {
  box-sizing: content-box;

  :not(&) {
    box-sizing: border-box;
  }
}

button.tv-fab {
  position: fixed;
  bottom: 30px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; // TODO - this is due to a bug in the material styles

  &:before {
    content: attr(aria-label);
    display: block;
    position: absolute;
    background: darken(map_get(map_get($theme, background), status-bar), 20%);
    color: white;
    right: 100%;
    margin-right: 10px;
    padding: 5px 10px;
    line-height: 1.3em;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;
  }

  &:hover:before {
    opacity: 1;
  }
}
