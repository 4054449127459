@use '@angular/material' as mat;

// Define a theme.
$primary: mat.define-palette(mat.$red-palette, 800);
$accent: mat.define-palette(mat.$deep-orange-palette);

$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

$primaryColor: mat.get-color-from-palette(map-get($theme, primary));
$primaryContrastColor: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
