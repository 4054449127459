.global-loading-spinner {
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > :first-child {
    margin-bottom: 10px;
  }

  > .loading-bar {
    background: #c62828;
    justify-self: stretch;
    height: 10px;
    animation-name: tv-loading-bar;
    animation-iteration-count: infinite;
    animation-duration: 3s;
  }
}


@keyframes tv-loading-bar {
  0% {
    margin-right: 100%;
    margin-left: 0;
  }

  50% {
    margin-right: 0;
    margin-left: 0;
  }

  100% {
    margin-left: 100%;
  }
}

